/**
 * Page for the conclusion of the pre-screener.
 */

import React from 'react';
import { Link, navigate } from 'gatsby';
import { Helmet } from 'react-helmet';
import Footer from '../components/FooterSimple';
import pagetools from '../util/pagetools';
import logo from '../images/logo.png';
import { localizer } from '../util/localizer';

export default function ThankYou(props) {
  const ctx = props.pageContext;
  const localized = localizer(ctx);
  const backHome = localized('/');
  const cfg = (props.location ? (props.location.state || {}) : {});
  const isBrowser = typeof window !== 'undefined';

  // Redirect to home page if page is accessed without proper parameters
  if (Object.keys(cfg).length===0) {
    if (isBrowser) navigate(backHome);
    return null;
  }
  const rootStyle = 'thank-you' + (cfg.theme ? ` ${cfg.theme}` : '');
  const btnStyle = 'slide-btn' + (cfg.theme ? ` btn-${cfg.theme}` : '');
  const sitedata = cfg.sitedata;
  const toplinks = pagetools.makeTopLinks(sitedata);
  const footdata = {
    ...sitedata.footer,
    toplinks,
    sitename: sitedata.sitename
  };
  if (isBrowser && window.threewireConsent) pageEvents(cfg.isQualified);
  const adImgTracker = <img height="1" width="1" style={{borderStyle:'none'}} alt="TO-BE-REPLACED" src="https://localhost:8000/favicon.ico" />;

  return (
    <div id="page">
      <Helmet htmlAttributes={{lang: ctx.locale}}>
        <meta charSet="utf-8" />
        <title>Thank You | {sitedata.sitename}</title>
        <link rel="canonical" href={`${sitedata.siteurl}/thankyou/`} />
        <meta name="description" content={cfg.header}></meta>
      </Helmet>
      <div className="container-fluid">
        <div className="container-fluid pt-8 pb-8">
          <div className="row">
            <div className="col-sm-6">
              <div id="logo">
                <Link to={backHome}>
                  <img src={logo} alt={sitedata.sitename} className="img-responsive" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="layout-root">
        <div className={rootStyle}>
          <h1 className="thank-you-h1">{cfg.header}</h1>
          {cfg.text.map((item, index) => <p key={`p-${index}`}>{item}</p>)}
          <div className="thank-you-btn">
            <Link to={backHome} className={btnStyle}>{sitedata.homelink}</Link>
          </div>
        </div>
        <Footer src={footdata} localizerfn={localized} />
      </div>
      {window.threewireConsent && adImgTracker}
    </div>
  );
};

// Run tracking tag features
function pageEvents(isQualified) {
  if (window.gtag) {
    window.gtag('event', 'conversion', { 'send_to': 'AW-10945197917/9ij-COSq98wDEN3uieMo' });
    window.gtag('event', 'form_submitted', { 'event_category': 'Screener', 'event_label': `Form submitted ${(isQualified ? 'Q' : 'DQ')}` });
  }
}
